<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import '@/assets/images/marketing/screenshots/Notifications_mobile.png';
import Action from '@/modules/marketing/MarketingAction.vue';
import { Slider, SliderItem } from 'vue-easy-slider';

@Component({
  components: {
    Action,
    Slider,
    SliderItem,
  },
  layout: 'default',
  metaInfo: {
    title: 'Compliance ',
  },
})
export default class Compliance extends Vue {}
</script>

<template>
  <main>
    <section class="py-24 md:pt-24 md:pb-12">
      <div class="bg-white">
        <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div class="container px-4 mx-auto">
            <div class="max-w-4xl mx-auto mb-20 text-center">
              <h1 class="leading-6 text-lg text-red-600 font-semibold tracking-wide uppercase">Compliance</h1>
              <h2 class="my-3 md:text-4xl text-gray-900 font-bold font-heading"
                >Guest check-in procedures & requirements for accommodation establishments in South Africa</h2
              >
              <p class="mt-3 text-gray-500"
                >As accommodation and service providers, there are a number of regulations that you need to comply with in terms of South
                African law. This includes what information you collect from guests and how you store it.
              </p>
              <p class="mt-3 text-gray-500">
                Not only will Check-In Buddy streamline your check-in process, it will also assist your organisation in being compliant with
                these key regulations:</p
              >
            </div>

            <!--Slider-->
            <!-- <div class="slider-wrap">
              <Slider class="features-slider" height="280px" animation="fade" :duration="5000" :speed="1000">
                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="relative">
                      <img src="@/assets/images/macbook.png" alt="mockup" />
                      <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                        <img
                          class="object-cover w-full h-full hoverZoomLink"
                          src="@/assets/images/marketing/screenshots/organisation_dashboard.jpg"
                          alt="organisation_dashboard_mockup"
                        />
                      </div>
                    </div>
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="relative">
                      <img src="@/assets/images/macbook.png" alt="mockup" />
                      <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                        <img
                          class="object-cover w-full h-full hoverZoomLink"
                          src="@/assets/images/marketing/screenshots/new_accommodation.jpg"
                          alt="organisation_dashboard_mockup"
                        />
                      </div>
                    </div>
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="relative">
                      <img src="@/assets/images/macbook.png" alt="mockup" />
                      <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                        <img
                          class="object-cover w-full h-full hoverZoomLink"
                          src="@/assets/images/marketing/screenshots/new_booking.jpg"
                          alt="organisation_dashboard_mockup"
                        />
                      </div>
                    </div>
                  </div>
                </SliderItem>

                <SliderItem>
                  <div class="relative max-w-6xl mx-auto mb-20">
                    <div class="relative">
                      <img src="@/assets/images/macbook.png" alt="mockup" />
                      <div class="absolute" style="top: 5.8%; left: 14.6%; width: 72.8%; height: 76.7%">
                        <img
                          class="object-cover w-full h-full hoverZoomLink"
                          src="@/assets/images/marketing/screenshots/bookings.jpg"
                          alt="organisation_dashboard_mockup"
                        />
                      </div>
                    </div>
                  </div>
                </SliderItem>
              </Slider>
            </div> -->

            <div class="mt-12 text-center mx-auto">
              <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                <div class="pt-6">
                  <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-red-500 rounded shadow-lg">
                          <!-- Heroicon name: outline/refresh -->
                          <svg
                            class="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                            />
                          </svg>
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">The Immigration Act 13 of 2002</h3>
                      <p class="mt-5 text-base text-gray-500"> Guest Identification Requirements in South Africa. </p>
                    </div>
                  </div>
                </div>
                <div class="pt-6">
                  <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-red-500 rounded shadow-lg">
                          <!-- Heroicon name: banner -->
                          <svg
                            class="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z"
                            ></path>
                          </svg>
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight">Disaster Management Act 57 of 2002</h3>
                      <p class="mt-5 text-base text-gray-500">
                        Directions on Risk Adjusted Strategy for Tourism Facilities, Services and Products: Guidelines to Prevent and Combat
                        the Spread of COVID-19.
                      </p>
                    </div>
                  </div>
                </div>
                <div class="pt-6">
                  <div class="flow-root bg-gray-50 rounded-lg px-6 pb-8">
                    <div class="-mt-6">
                      <div>
                        <span class="inline-flex items-center justify-center p-3 bg-red-500 rounded shadow-lg">
                          <!-- Heroicon name: outline/lock-closed -->
                          <svg
                            class="h-6 w-6 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                              d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"
                            />
                          </svg>
                        </span>
                      </div>
                      <h3 class="mt-8 text-lg font-medium text-gray-900 tracking-tight"
                        >The Protection of Personal Information Act 4 of 2013</h3
                      >
                      <p class="mt-5 text-base text-gray-500"> The processing of personal information. </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <section class="pt-32 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
              <div class="max-w-max lg:max-w-7xl mx-auto md:px-6 text-center">
                <h2 class="my-3 max-w-6xl mx-auto md:text-4xl text-gray-900 font-bold font-heading"
                  >How we help you with regulatory compliance in South Africa</h2
                >
                <p class="mt-4 max-w-2xl text-xl mx-auto text-gray-500">
                  At Check-In Buddy we have done our homework and specifically built our software with key regulations in mind.
                </p>
              </div>
            </section>

            <div class="pt-32 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
              <div class="max-w-max lg:max-w-7xl mx-auto">
                <div class="relative z-10 mb-8 md:mb-2 md:px-6">
                  <div class="text-base max-w-prose lg:max-w-none">
                    <h2 class="leading-6 text-xl text-red-600 font-semibold tracking-wide uppercase">The Immigration Act 13 of 2002</h2>
                    <p class="mt-2 text-3xl leading-8 text-gray-900 font-bold font-heading sm:text-4xl md:text-4xl"
                      >Guest Identification Requirements in South Africa</p
                    >
                  </div>
                </div>
                <div class="relative">
                  <div class="relative md:bg-white md:p-6">
                    <div class="lg:grid lg:grid-cols-2 lg:gap-6">
                      <div class="prose prose-red prose-base text-gray-500 lg:max-w-none">
                        <p
                          >This regulation is
                          <strong>
                            relevant to all establishments where lodging or sleeping accommodation is provided for payment or reward
                          </strong>
                          (including hotels, motels, boarding houses, lodges, guest houses, and apartments).</p
                        >
                        <p
                          >Section 40(1) of the Act, read together with Regulation 36, states that
                          <strong>
                            all establishments must keep a register containing details of all persons who are provided with lodging or
                            sleeping accommodation.
                          </strong>
                          This information must be collected irrespective of whether the guest is a South African resident or not, and must
                          be kept for a period of 2 years.
                        </p>
                        <p
                          >So, what information do you need to collect? The Act stipulates that the register of guests must contain the
                          following information:
                        </p>
                        <ol>
                          <li>His or her full names and surname;</li>
                          <li>A copy of his or her identification document or passport;</li>
                          <li>His or her residence status in the Republic; and</li>
                          <li>His or her normal residential address.</li>
                        </ol>
                      </div>
                      <div class="mt-6 prose prose-red prose-base text-gray-500 lg:mt-0">
                        <p
                          >Furthermore, each guest must furnish their own prescribed particulars and sign the register. Penalties for
                          non-compliance include a fine and/or imprisonment for a period not exceeding 12 months.</p
                        >
                        <p class="font-semibold">So how does Check-In Buddy help you comply with this regulation? </p>
                        <ul>
                          <li
                            >In order to complete their online check-in, each guest must provide, at minimum, all of the required
                            information outlined by the Act.
                          </li>
                          <li>Each guest must upload a copy of their ID or passport.</li>
                          <li>Each guest digitally signs your establishment’s indemnity.</li>
                          <li
                            >This information is stored under the guest's profile indefinitely. You control when this information is
                            deleted.</li
                          >
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="pt-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
              <div class="max-w-max lg:max-w-7xl mx-auto">
                <div class="relative z-10 mb-8 md:mb-2 md:px-6">
                  <div class="text-base max-w-prose lg:max-w-none">
                    <h2 class="leading-6 text-xl text-red-600 font-semibold tracking-wide uppercase">Disaster Management Act 57 of 2002</h2>
                    <p class="mt-2 text-3xl leading-8 text-gray-900 font-bold font-heading sm:text-4xl md:text-4xl"
                      >Guidelines to Prevent and Combat the Spread of COVID-19</p
                    >
                  </div>
                </div>
                <div class="relative">
                  <div class="relative md:bg-white md:p-6">
                    <div class="lg:grid lg:grid-cols-2 lg:gap-6">
                      <div class="prose prose-red prose-base text-gray-500 lg:max-w-none">
                        <p
                          >Due to the worldwide COVID-19 pandemic, South Africa instituted a National State of Disaster and new regulations
                          came into play in 2020 in order to prevent and combat the spread of the virus.
                        </p>
                        <p
                          >As part of these regulations and the risk adjusted strategy for tourism facilities, establishments must maintain
                          certain records. Establishments must:
                        </p>
                        <ol>
                          <li
                            >Keep a daily record of the full names, identity number or passport number, nationality, residential address and
                            cell phone numbers of all guests.</li
                          >
                          <li
                            >Conduct a screening questionnaire and take precautionary measures to protect the person and other persons on
                            the premises.</li
                          >
                          <li>Make such record available, should it be required in terms of the regulations and by the minister.</li>
                          <li
                            >Keep the record for the duration of the national state of disaster and retain the record for a period of six
                            weeks after the national state of disaster ends.</li
                          >
                        </ol>
                        <p>Penalties for non-compliance include a fine and/or imprisonment for a period not exceeding 6 months. </p>
                      </div>
                      <div class="mt-6 prose prose-red prose-base text-gray-500 lg:mt-0">
                        <p class="font-semibold">So how does Check-In Buddy help you to comply with this regulation? </p>
                        <ul>
                          <li
                            >In order to complete their online check-in, each guest must provide, at minimum, all of the required
                            information outlined by the Act.
                          </li>
                          <li>Each guest must complete a health check questionnaire within 24 hours prior to arrival.</li>
                          <li>You can record multiple temperature readings to a guest's profile.</li>
                          <li
                            >This information is stored under the guest's profile indefinitely. You control when this information is
                            deleted.</li
                          >
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="py-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
              <div class="max-w-max lg:max-w-7xl mx-auto">
                <div class="relative z-10 mb-8 md:mb-2 md:px-6">
                  <div class="text-base max-w-prose lg:max-w-none">
                    <h2 class="leading-6 text-xl text-red-600 font-semibold tracking-wide uppercase"
                      >The Protection of Personal Information Act 4 of 2013</h2
                    >
                    <p class="mt-2 text-3xl leading-8 text-gray-900 font-bold font-heading sm:text-4xl md:text-4xl"
                      >The processing of personal information</p
                    >
                  </div>
                </div>
                <div class="relative">
                  <div class="relative md:bg-white md:p-6">
                    <div class="lg:grid lg:grid-cols-2 lg:gap-6">
                      <div class="prose prose-red prose-base text-gray-500 lg:max-w-none">
                        <p
                          >With the introduction of the Protection of Personal Information Act (POPIA), which came into full force on 1 July
                          2021,
                          <strong>
                            businesses in the hospitality industry take on a new set of responsibilities to protect their guests’ personal
                            information.
                          </strong>
                          POPIA requires a business to put in place 'appropriate reasonable technical and organisational measures’ to
                          prevent loss, theft or damage to personal information.</p
                        >
                        <p
                          >Furthermore POPIA provides guidance on how information may be collected and how long it should be retained.
                          <strong>The general principle is “less is more”.</strong> This means you should only collect information that is
                          necessary to allow you to provide services to your guests and that you shouldn’t keep this information for longer
                          than is necessary to render services. However, regard must be given to other legislation which sets specific
                          timeframes for the retention of documents, such as the Companies Act, the Income Tax Act, and the BCEA.</p
                        >
                        <p>Penalties for non-compliance are severe and include fines and/or imprisonment. </p>
                      </div>
                      <div class="mt-6 prose prose-red prose-base text-gray-500 lg:mt-0">
                        <p class="font-semibold">So how does Check-In Buddy help you to comply with this regulation? </p>
                        <ul>
                          <li
                            >We have carefully considered what personal information should be provided by guests. The mandatory information
                            required by Check-In Buddy is limited to the information required by the Immigration Act. The guest has the
                            option to provide additional information that may be necessary to allow establishments to cater for their
                            special requirements.
                          </li>
                          <li
                            >Check-In Buddy stores all data in cloud-based servers with arguably one of the most reputable companies in the
                            world.</li
                          >
                          <li
                            >This information is stored under the guest's profile indefinitely. You control when this information is
                            deleted.</li
                          >
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <Action />
  </main>
</template>

<style lang="css">
.slider-wrap {
  position: relative;
  z-index: 10;
}

.features-slider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 500px) {
  .features-slider {
    height: 330px !important;
  }
}

@media only screen and (min-width: 600px) {
  .features-slider {
    height: 380px !important;
  }
}

@media only screen and (min-width: 768px) {
  .features-slider {
    height: 460px !important;
  }
}

@media only screen and (min-width: 1024px) {
  .features-slider {
    height: 580px !important;
  }
}

@media only screen and (min-width: 1150px) {
  .features-slider {
    height: 650px !important;
  }
}

@media only screen and (min-width: 1440px) {
  .features-slider {
    height: 700px !important;
  }
}

.slider-btn-right {
  background: none !important;
}

.slider-btn-left {
  background: none !important;
}
</style>
